// import './App.css';
import axios from 'axios'
import { ServiceHelper } from './service-helper';
import { useState } from 'react';

function App() {

	const[formResponse, setFormResponse] = useState(false);

	async function handleSubmit(event) {
		event.preventDefault();
		const data = new FormData(event.target);
		const values = Object.fromEntries(data);
		// console.log(values);
		const apiAddress = "https://mustafayonca.com/api/sendEmail";
		// const apiAddress = "/sendEmail";
		let response = await ServiceHelper.post(apiAddress, values)
		// console.log(response);
		setFormResponse(true);
	}



	return (
		<div className="App">

			<section class="preloader">
				<div class="spinner">
					<span class="spinner-rotate"></span>
				</div>
			</section>

			<nav class="navbar navbar-expand-lg">
				<div class="container">

					<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav"
						aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
						<span class="navbar-toggler-icon"></span>
					</button>

					<a href="index.html" class="navbar-brand mx-auto mx-lg-0">MSTFYNC</a>

					<div class="d-flex align-items-center d-lg-none">
						<i class="navbar-icon bi-instagram me-3" style={{ cursor: 'pointer' }} onClick={() => { window.location.href = "https://www.instagram.com/mstfync.dev/" }}></i>
						<i class="navbar-icon bi-youtube me-3" style={{ cursor: 'pointer' }} onClick={() => { window.location.href = "https://www.youtube.com/@mstfyncdev" }}></i>
						<i class="navbar-icon bi-twitter me-3" style={{ cursor: 'pointer' }} onClick={() => { window.location.href = "https://twitter.com/mstfync_dev" }}></i>
					</div>

					<div class="collapse navbar-collapse" id="navbarNav">
						<ul class="navbar-nav ms-lg-5">
							<li class="nav-item">
								<a class="nav-link click-scroll" href="#section_1">Home</a>
							</li>

							<li class="nav-item">
								<a class="nav-link click-scroll" href="#section_2">Blog</a>
							</li>

							<li class="nav-item">
								<a class="nav-link click-scroll" href="#section_3">Services</a>
							</li>

							<li class="nav-item">
								<a class="nav-link click-scroll" href="#section_4">Projects</a>
							</li>

							<li class="nav-item">
								<a class="nav-link click-scroll" href="#section_5">Contact</a>
							</li>
						</ul>

						<div class="d-lg-flex align-items-center d-none ms-auto">
							<i class="navbar-icon bi-instagram me-3" style={{ cursor: 'pointer' }} onClick={() => { window.location.href = "https://www.instagram.com/mstfync.dev/" }}></i>
							<i class="navbar-icon bi-youtube me-3" style={{ cursor: 'pointer' }} onClick={() => { window.location.href = "https://www.youtube.com/@mstfyncdev" }}></i>
							<i class="navbar-icon bi-twitter me-3" style={{ cursor: 'pointer' }} onClick={() => { window.location.href = "https://twitter.com/mstfync_dev" }}></i>
						</div>
					</div>

				</div>
			</nav>

			<main>
				<section class="hero d-flex justify-content-center align-items-center" id="section_1">
					<div class="container">
						<div class="row">

							<div class="col-lg-7 col-12">
								<div class="hero-text">
									<div class="hero-title-wrap d-flex align-items-center mb-4">
										<img src="images/mstfync.png"
											class="avatar-image avatar-image-large img-fluid" alt="" />

										<h1 class="hero-title ms-3 mb-0">Hello!</h1>
									</div>

									<h2 class="mb-4">Welcome to my website</h2>
									<p class="mb-4"><a class="custom-btn btn custom-link" href="#section_2">Let's begin</a></p>
								</div>
							</div>

							<div class="col-lg-5 col-12 position-relative">
								<div class="hero-image-wrap"></div>
								<img src="images/mstfync-big.png"
									class="hero-image img-fluid" alt="" />
							</div>

						</div>
					</div>

					<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
						<path fill="#535da1" fill-opacity="1"
							d="M0,160L24,160C48,160,96,160,144,138.7C192,117,240,75,288,64C336,53,384,75,432,106.7C480,139,528,181,576,208C624,235,672,245,720,240C768,235,816,213,864,186.7C912,160,960,128,1008,133.3C1056,139,1104,181,1152,202.7C1200,224,1248,224,1296,197.3C1344,171,1392,117,1416,90.7L1440,64L1440,0L1416,0C1392,0,1344,0,1296,0C1248,0,1200,0,1152,0C1104,0,1056,0,1008,0C960,0,912,0,864,0C816,0,768,0,720,0C672,0,624,0,576,0C528,0,480,0,432,0C384,0,336,0,288,0C240,0,192,0,144,0C96,0,48,0,24,0L0,0Z">
						</path>
					</svg>
				</section>

				<section class="about section-padding" id="section_2">
					<div class="container">
						<div class="row">

							<div class="col-lg-6 col-12">
								<img src="images/mstfync-work.jpg" class="about-image img-fluid"
									alt="" />
							</div>

							<div class="col-lg-6 col-12 mt-5 mt-lg-0">
								<div class="about-thumb">

									<div class="section-title-wrap d-flex justify-content-end align-items-center mb-4">
										<h2 class="text-white me-4 mb-0">My Blog</h2>

										<img src="images/mstfync.png" class="avatar-image img-fluid" alt="" />
									</div>

									<h3 class="pt-2 mb-3">a little bit about Me</h3>

									<p>Software Architect - Full Stack Developer</p>
									<div class="row">
										<div class="col-lg-6 col-12">
											<ul>
												<li>React</li>
												<li>NodeJS</li>
												<li>React Native</li>
											</ul>
										</div>
										<div class="col-lg-6 col-12">
											<ul>
												<li>.NET</li>
												<li>MSSQL</li>
												<li>MYSQL</li>
											</ul>
										</div>
									</div>
								</div>
							</div>

						</div>
					</div>
				</section>

				<section class="services section-padding" id="section_3">
					<div class="container">
						<div class="row">

							<div class="col-lg-10 col-12 mx-auto">
								<div class="section-title-wrap d-flex justify-content-center align-items-center mb-5">
									<img src="images/handshake-man-woman-after-signing-business-contract-closeup.jpg"
										class="avatar-image img-fluid" alt="" />

									<h2 class="text-white ms-4 mb-0">Services</h2>
								</div>

								<div class="row pt-lg-5">
									<div class="col-lg-6 col-12">
										<div class="services-thumb">
											<div class="d-flex flex-wrap align-items-center border-bottom mb-4 pb-3">
												<h3 class="mb-0">Websites</h3>

												{/* <div class="services-price-wrap ms-auto">
                                            <p class="services-price-text mb-0">$2,400</p>
                                            <div class="services-price-overlay"></div>
                                        </div>  */}
											</div>

											<p>Under Construction
											</p>

											<a href="#" class="custom-btn custom-border-btn btn mt-3">Discover More</a>

											<div class="services-icon-wrap d-flex justify-content-center align-items-center">
												<i class="services-icon bi-globe"></i>
											</div>
										</div>
									</div>

									<div class="col-lg-6 col-12">
										<div class="services-thumb services-thumb-up">
											<div class="d-flex flex-wrap align-items-center border-bottom mb-4 pb-3">
												<h3 class="mb-0">Mobile App</h3>

												{/* <div class="services-price-wrap ms-auto">
                                            <p class="services-price-text mb-0">$1,200</p>
                                            <div class="services-price-overlay"></div>
                                        </div> */}
											</div>

											<p>Under Construction</p>

											<a href="#" class="custom-btn custom-border-btn btn mt-3">Discover More</a>

											<div class="services-icon-wrap d-flex justify-content-center align-items-center">
												<i class="services-icon bi-lightbulb"></i>
											</div>
										</div>
									</div>

									<div class="col-lg-6 col-12">
										<div class="services-thumb">
											<div class="d-flex flex-wrap align-items-center border-bottom mb-4 pb-3">
												<h3 class="mb-0">Ecommerce</h3>

												{/* <div class="services-price-wrap ms-auto">
                                            <p class="services-price-text mb-0">$3,600</p>
                                            <div class="services-price-overlay"></div>
                                        </div>  */}
											</div>

											<p>Under Construction</p>

											<a href="#" class="custom-btn custom-border-btn btn mt-3">Discover More</a>

											<div class="services-icon-wrap d-flex justify-content-center align-items-center">
												<i class="services-icon bi-phone"></i>
											</div>
										</div>
									</div>

									<div class="col-lg-6 col-12">
										<div class="services-thumb services-thumb-up">
											<div class="d-flex flex-wrap align-items-center border-bottom mb-4 pb-3">
												<h3 class="mb-0">SEO</h3>
												{/* 
                                         <div class="services-price-wrap ms-auto">
                                            <p class="services-price-text mb-0">$1,450</p>
                                            <div class="services-price-overlay"></div>
                                        </div>  */}
											</div>

											<p>Under Construction</p>

											<a href="#" class="custom-btn custom-border-btn btn mt-3">Discover More</a>

											<div class="services-icon-wrap d-flex justify-content-center align-items-center">
												<i class="services-icon bi-google"></i>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</section>

				<section class="projects section-padding" id="section_4">
					<div class="container">
						<div class="row">

							<div class="col-lg-8 col-md-8 col-12 ms-auto">
								<div class="section-title-wrap d-flex justify-content-center align-items-center mb-4">
									<img src="images/white-desk-work-study-aesthetics.jpg" class="avatar-image img-fluid"
										alt="" />

									<h2 class="text-white ms-4 mb-0">Projects</h2>
								</div>
							</div>

							<div class="clearfix"></div>

							<div class="col-lg-4 col-md-6 col-12">
								<div class="projects-thumb">
									<div class="projects-info">
										<small class="projects-tag">Mobile App</small>

										<h3 class="projects-title">İstanbulum</h3>
									</div>

									<a href="https://play.google.com/store/apps/details?id=com.istanbulum&hl=tr&gl=US" target="_blank" rel="noreferrer" class="popup-image">
										<img src="images/projects/istanbulum.png"
											class="projects-image img-fluid" alt="" />
									</a>
								</div>
							</div>

							<div class="col-lg-4 col-md-6 col-12">
								<div class="projects-thumb">
									<div class="projects-info">
										<small class="projects-tag">Web Site</small>

										<h3 class="projects-title">Ailecek İzliyoruz</h3>
									</div>

									<a href="https://www.ailecekizliyoruz.com/" target="_blank" rel="noreferrer" class="popup-image">
										<img src="images/projects/ailecek-izliyoruz.png"
											class="projects-image img-fluid" alt="" />
									</a>
								</div>
							</div>

							<div class="col-lg-4 col-md-6 col-12">
								<div class="projects-thumb">
									<div class="projects-info">
										<small class="projects-tag">Mobile App</small>

										<h3 class="projects-title">Hatm-i Hacegan</h3>
									</div>

									<a href="https://play.google.com/store/apps/details?id=com.mstfync.hacegan&hl=tr" target="_blank" rel="noreferrer" class="popup-image">
										<img src="images/projects/hatmihacegan.png"
											class="projects-image img-fluid" alt="" />
									</a>
								</div>
							</div>

						</div>
					</div>
				</section>




				<section class="contact section-padding" id="section_5">
					<div class="container">
						<div class="row">

							<div class="col-lg-6 col-md-8 col-12">
								<div class="section-title-wrap d-flex justify-content-center align-items-center mb-5">
									<img src="images/aerial-view-man-using-computer-laptop-wooden-table.jpg"
										class="avatar-image img-fluid" alt="" />

									<h2 class="text-white ms-4 mb-0">Say Hi</h2>
								</div>
							</div>

							<div class="clearfix"></div>

							<div class="col-lg-3 col-md-6 col-12 pe-lg-0">
								<div class="contact-info contact-info-border-start d-flex flex-column">
									{/* <!-- <strong class="site-footer-title d-block mb-3">Services</strong>

                            <ul class="footer-menu">
                                <li class="footer-menu-item"><a href="#" class="footer-menu-link">Websites</a></li>

                                <li class="footer-menu-item"><a href="#" class="footer-menu-link">Branding</a></li>

                                <li class="footer-menu-item"><a href="#" class="footer-menu-link">Ecommerce</a></li>

                                <li class="footer-menu-item"><a href="#" class="footer-menu-link">SEO</a></li>
                            </ul> --> */}

									<strong class="site-footer-title d-block mt-4 mb-3">Stay connected</strong>

									<ul class="social-icon">
										<li class="social-icon-item"><a href="https://twitter.com/mstfync_dev" class="social-icon-link bi-twitter"></a></li>

										<li class="social-icon-item"><a href="https://www.instagram.com/mstfync.dev/" class="social-icon-link bi-instagram"></a></li>

										<li class="social-icon-item"><a href="https://www.youtube.com/@mstfyncdev" class="social-icon-link bi-youtube"></a></li>
									</ul>

									<strong class="site-footer-title d-block mt-4 mb-3">Start a project</strong>

									<p class="mb-0">I’m available for freelance projects</p>
								</div>
							</div>

							<div class="col-lg-3 col-md-6 col-12 ps-lg-0">
								<div class="contact-info d-flex flex-column">
									<strong class="site-footer-title d-block mb-3">About</strong>

									<p class="mb-2">
										Mustafa is a professional full stack developer. Feel free to get in touch with me.
									</p>

									<strong class="site-footer-title d-block mt-4 mb-3">Email</strong>

									<p>
										<a href="mailto:hello@josh.design">
											info@mustafayonca.com
										</a>
									</p>

									{/* <!-- <strong class="site-footer-title d-block mt-4 mb-3">Call</strong>

                            <p class="mb-0">
                                <a href="tel: 120-240-9600">
                                    120-240-9600
                                </a>
                            </p> --> */}
								</div>
							</div>

							<div class="col-lg-6 col-12 mt-5 mt-lg-0">
								<form class="custom-form contact-form" onSubmit={handleSubmit}>
									<div class="row">
										<div class="col-lg-6 col-md-6 col-12">
											<div class="form-floating">
												<input type="text" name="name" id="name" class="form-control" placeholder="Name"
													required="" />

												<label for="floatingInput">Name</label>
											</div>
										</div>

										<div class="col-lg-6 col-md-6 col-12">
											<div class="form-floating">
												<input type="email" name="email" id="email" pattern="[^ @]*@[^ @]*"
													class="form-control" placeholder="Email address" required="" />

												<label for="floatingInput">Email address</label>
											</div>
										</div>

										<div class="col-lg-3 col-md-6 col-6">
											<div class="form-check form-check-inline">
												<input name="website" type="checkbox" class="form-check-input"
													id="inlineCheckbox1" value="1" />

												<label class="form-check-label" for="inlineCheckbox1">
													<i class="bi-globe form-check-icon"></i>
													<span class="form-check-label-text">Websites</span>
												</label>
											</div>
										</div>

										<div class="col-lg-3 col-md-6 col-6">
											<div class="form-check form-check-inline">
												<input name="branding" type="checkbox" class="form-check-input"
													id="inlineCheckbox2" value="1" />

												<label class="form-check-label" for="inlineCheckbox2">
													<i class="bi-lightbulb form-check-icon"></i>
													<span class="form-check-label-text">Mobile App</span>
												</label>
											</div>
										</div>

										<div class="col-lg-3 col-md-6 col-6">
											<div class="form-check form-check-inline">
												<input name="ecommerce" type="checkbox" class="form-check-input"
													id="inlineCheckbox3" value="1" />

												<label class="form-check-label" for="inlineCheckbox3">
													<i class="bi-phone form-check-icon"></i>
													<span class="form-check-label-text">Ecommerce</span>
												</label>
											</div>
										</div>

										<div class="col-lg-3 col-md-6 col-6">
											<div class="form-check form-check-inline me-0">
												<input name="seo" type="checkbox" class="form-check-input" id="inlineCheckbox4"
													value="1" />

												<label class="form-check-label" for="inlineCheckbox4">
													<i class="bi-google form-check-icon"></i>
													<span class="form-check-label-text">SEO</span>
												</label>
											</div>
										</div>

										<div class="col-lg-12 col-12">
											<div class="form-floating">
												<textarea class="form-control" id="message" name="message"
													placeholder="Tell me about the project"></textarea>

												<label for="floatingTextarea">Tell me about the project</label>
											</div>
										</div>
										{
											formResponse &&
											<div class="col-lg-12 col-12">
												<div class="form-floating">
													<label style={{ color: '#13b789' }} for="floatingTextarea">Your message has been delivered successfully</label>
												</div>
											</div>
										}
										<div class="col-lg-3 col-12 ms-auto">
											<button type="submit" class="form-control">Send</button>
										</div>

									</div>
								</form>
							</div>

						</div>
					</div>
				</section>



			</main>

			<footer class="site-footer">
				<div class="container">
					<div class="row">

						<div class="col-lg-12 col-12">
							<div class="copyright-text-wrap">
								<span>
									Copyright © 2023 <a href="#">MSTFYNC </a> Company. All
									rights reserved.</span>
							</div>
						</div>

					</div>
				</div>
			</footer>
		</div>
	);
}

export default App;
