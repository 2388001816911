function get(url, params) {
  const requestOptions = {
    method: 'GET',
    headers: { 'Content-Type': 'application/json', 'x-access-token': localStorage.getItem('token') }
  };
  return fetch(url, requestOptions)
    .then(handleResponse);
}

function getWithoutToken(url) {
  const requestOptions = {
    method: 'GET'
  };
  return fetch(url, requestOptions)
    .then(handleResponse);
}

function post(url, body) {
  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json', 'x-access-token': localStorage.getItem('token') },
    body: JSON.stringify(body)
  };
  return fetch(url, requestOptions)
    .then(handleResponse)
    .catch(handleError);
}

function put(url, body) {
  const requestOptions = {
    method: 'PUT',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(body)
  };
  fetch(url, requestOptions)
    .then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
function _delete(url, params) {
  const requestOptions = {
    method: 'DELETE',
    headers: { 'Content-Type': 'application/json', 'x-access-token': localStorage.getItem('token') }
  };
  return fetch(url, requestOptions)
    .then(handleResponse);
}

// helper functions

function handleResponse(response) {
  return response.text().then(text => {
    const data = text && JSON.parse(text);

    // if (!response.ok) {
    //   const error = (data && data.message) || response.statusText;
    //   return Promise.reject(error);
    // }

    return data;
  });
}

function handleError(response) {
  return response;
}

export const ServiceHelper = {
  get,
  getWithoutToken,
  post,
  put,
  delete: _delete
};
